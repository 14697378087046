<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                class="text-center"
            >
                <v-icon
                    size="100"
                >
                    mdi-alert-circle
                </v-icon>
                <div class="text-center">
                    <h1>{{ $t('anErrorHappened') }}</h1>
                    <span>{{ $t('errorInfo') }}</span>
                    <br>
                    <code v-if="error">
                        {{ 'Fejl: ' + error }}
                    </code>
                </div>
                <v-btn
                    class="my-2"
                    :to="{ name: 'Login' }"
                >
                    {{ $t('customerportal-goToLogin') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            error: localStorage.getItem('error')
        }
    },
    computed: {
    },
    created() {        
    },    
    methods: {
    }
}
</script>
